import axios from "axios";
import { PaymentLinks } from "../models";

export const PaymentService = {

    async getPagesByParentPageId(userId: number, amount: number) {
        try {
            const response = await axios.get<PaymentLinks>(`/api/getPaymentLinks?userId=${userId}&amount=${amount}`);

            return response.data;
        } catch (error) {
            return undefined;
        }
    }
}