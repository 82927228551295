import React, { useState } from 'react';
import { FundAccountInput } from './FundAccountInput';
import { UserService } from '../../services';
import { Banner } from '../Banners/Banner';
import { FundAmount } from './FundAmount';
import { PaymentService } from '../../services/paymentService';
import { PaymentLinks } from '../../models';
import { PaymentOptions } from './PaymentOptions';

export const FundAccount: React.FC = () => {
    const [userId, setUserId] = useState('');
    const [amount, setAmount] = useState(1250);
    const [banner, setBanner] = useState<{ message: string, type: 'success' | 'error' } | null>(null);
    const [paymentLinks, setPaymentLinks] = useState<PaymentLinks | undefined>(undefined);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserId(e.target.value);
    };

    const handleAmountChange = (value: number) => {
        setAmount(value);
    };

    const handleButtonClick = async () => {
        const id = parseInt(userId, 10);

        if (isNaN(id)) {
            setBanner({
                message: "Мы не смогли проверить указанный ID. Возможно вы ошиблись с написанием.",
                type: 'error'
            });
            return;
        }

        try {
            const userData = await UserService.getUserByUserId(id);
            if (userData) {
                setBanner({ message: "Проверено", type: 'success' });
            } else {
                setBanner({
                    message: "Мы не смогли проверить указанный ID. Возможно вы ошиблись с написанием.",
                    type: 'error'
                });
            }
        } catch (error) {
            setBanner({
                message: "Мы не смогли проверить указанный ID. Возможно вы ошиблись с написанием.",
                type: 'error'
            });
        }
    };

    const handleGeneratePayments = async () => {
        const id = parseInt(userId, 10);

        if (isNaN(id)) {
            setBanner({
                message: "Пожалуйста, введите корректный ID пользователя.",
                type: 'error'
            });
            return;
        }

        try {
            const links = await PaymentService.getPagesByParentPageId(id, amount);

            if (links) {
                setPaymentLinks(links);
                setBanner({
                    message: "Платежи успешно сгенерированы.",
                    type: 'success'
                });
            } else {
                setBanner({
                    message: "Не удалось сгенерировать платежи. Попробуйте снова.",
                    type: 'error'
                });
            }
        } catch (error) {
            setBanner({
                message: "Ошибка при генерации платежей. Попробуйте еще раз.",
                type: 'error'
            });
        }
    };

    return (
        <div className="flex justify-center p-4 sm:p-6 md:p-10">
            <div className="flex flex-col w-full sm:w-4/5 md:w-3/5 rounded-lg p-4 sm:p-6 md:p-8 bg-gray-800">
                <h1 className="text-white text-2xl sm:text-3xl md:text-4xl mb-4 text-center">Пополнение баланса @BubShopBot</h1>
                <FundAccountInput
                    value={userId}
                    onChange={handleInputChange}
                    onButtonClick={handleButtonClick}
                />
                {banner && <Banner message={banner.message} type={banner.type} />}
                <FundAmount value={amount} onChange={handleAmountChange} />
                <button
                    className="mt-6 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition-colors duration-300"
                    onClick={handleGeneratePayments}
                >
                    Сгенерировать платежи
                </button>
                {paymentLinks && <PaymentOptions links={paymentLinks} />}
            </div>
        </div>
    );
};
