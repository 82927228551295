import React from 'react';

interface FundAmountProps {
    value: number;
    onChange: (value: number) => void;
}

const getHotkeyColor = (value: number) => {
    if (value <= 300) return 'bg-emerald-700 hover:bg-emerald-800';
    if (value <= 1250) return 'bg-yellow-700 hover:bg-yellow-800';
    if (value <= 5000) return 'bg-indigo-700 hover:bg-indigo-800';
    return 'bg-rose-800 hover:bg-rose-700';
};

export const FundAmount: React.FC<FundAmountProps> = ({ value, onChange }) => {

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const amountValue = parseInt(e.target.value, 10);
        onChange(isNaN(amountValue) ? 0 : amountValue);
    };

    const handleHotkeyClick = (value: number) => {
        onChange(value);
    };

    const hotkeys = [100, 300, 500, 1000, 1250, 2500, 5000, 10000];

    return (
        <div className="mt-6 sm:mt-8 bg-gray-700 rounded-lg p-4 sm:p-6 shadow-lg">
            <div className="flex flex-col">
                <label htmlFor="amount" className="text-sm text-gray-300 mb-2">
                    Сумма пополнения
                </label>
                <div className="relative">
                    <input
                        id="amount"
                        className="w-full bg-gray-800 text-white placeholder-gray-500 text-xl sm:text-3xl focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg py-2 sm:py-3 px-3 sm:px-4 appearance-none"
                        placeholder="Введите сумму"
                        value={value}
                        onChange={handleAmountChange}
                    />
                    <span className="absolute right-3 sm:right-4 top-1/2 transform -translate-y-1/2 text-gray-400 text-lg sm:text-xl">
                        ₽
                    </span>
                </div>
            </div>
            <div className="mt-4 sm:mt-6">
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-3">
                    {hotkeys.map((hotkeyValue) => (
                        <button
                            key={hotkeyValue}
                            className={`py-2 px-3 sm:px-4 rounded-lg text-xs sm:text-sm font-medium transition-colors duration-200 ${value === hotkeyValue
                                    ? 'ring-2 ring-white text-white'
                                    : 'text-gray-200 hover:text-white'
                                } ${getHotkeyColor(hotkeyValue)}`}
                            onClick={() => handleHotkeyClick(hotkeyValue)}
                        >
                            {hotkeyValue.toLocaleString()} ₽
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};
