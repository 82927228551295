import React from 'react';
import { Page } from '../../models';
import { PageItem } from '..';
import { useFetchPages } from '../../hooks';

export const MainPage: React.FC = () => {
    const { responseData, loading } = useFetchPages();

    if (loading) {
        return <p className='text-4xl'>Loading...</p>;
    }

    return (
        <div className="relative">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 relative z-10">
                {responseData.map((page: Page) => (
                    <PageItem key={page.pageId} page={page} />
                ))}
            </div>
        </div>
    );
};
