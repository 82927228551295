import React from 'react';
import { SendHorizontal } from 'lucide-react';

interface FundAccountInputProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    onButtonClick: () => void;
}

export const FundAccountInput: React.FC<FundAccountInputProps> = ({ onChange, value, onButtonClick }) => {
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onButtonClick();
        }
    };

    return (
        <div className='w-full rounded-lg bg-gray-700 p-4 sm:p-6 shadow-lg'>
            <label htmlFor="userId" className="block text-base sm:text-lg font-medium text-gray-200 mb-2 sm:mb-3">
                ID пользователя
            </label>
            <div className='relative flex items-center'>
                <input
                    id="userId"
                    className='flex-grow w-full text-white placeholder-gray-400 text-lg sm:text-xl 
                               focus:outline-none focus:ring-2 focus:ring-blue-500 
                               bg-gray-800 rounded-lg py-3 sm:py-4 px-4 sm:px-5 pr-12 sm:pr-14
                               border border-gray-600 focus:border-blue-500
                               transition-all duration-300 ease-in-out'
                    placeholder='ID из профиля @BubShopBot'
                    onChange={onChange}
                    value={value}
                    onKeyDown={handleKeyDown}
                />
                <button
                    className='absolute right-3 p-2 text-gray-400 hover:text-blue-500 
                               transition-colors duration-300 focus:outline-none'
                    onClick={onButtonClick}
                    aria-label="Send"
                >
                    <SendHorizontal className='w-5 h-5 sm:w-6 sm:h-6' />
                </button>
            </div>
            <p className="mt-2 text-xs sm:text-sm text-gray-400">
                Введите ID пользователя из профиля @BubShopBot для пополнения баланса
            </p>
        </div>
    );
};