import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { PageService, ProductService } from '../../services';
import { Page, Product } from '../../models';
import { PageItem, ProductItem } from '..';

export const PageComponent: React.FC = () => {
    const { pageId } = useParams<{ pageId: string }>();
    const navigate = useNavigate();
    const [pages, setPages] = useState<Page[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [loading, setLoading] = useState(true);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const pagesData = await PageService.getPagesByParentPageId(Number(pageId));
            const productsData = await ProductService.getProductsByPageId(Number(pageId));

            setPages(pagesData);
            setProducts(productsData);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [pageId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className="relative p-4">
            {loading ? (
                <p className='text-4xl'>Loading...</p>
            ) : (
                <div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {pages.map(page => (
                            <PageItem key={page.pageId} page={page} />
                        ))}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
                        {products.map(product => (
                            <ProductItem key={product.productId} product={product} />
                        ))}
                    </div>
                    
                    <div className="mt-8">
                        <button 
                            onClick={() => navigate(-1)} // Navigate back to the previous page
                            className="bg-gray-900 p-4 rounded-lg shadow-md text-white text-xl transition duration-300 hover:bg-gray-950 w-2/5"
                        >
                            Назад
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};
