import axios from "axios";
import { Page } from "../models";

export const PageService = {

    async getPagesByParentPageId(parentPageId: number) {
        try {
            const response = await axios.get<Page[]>(`/api/pages/getPagesByParentPageId/${parentPageId}`);

            return response.data;
        } catch (error) {
            return [];
        }
    }
}