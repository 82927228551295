import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../models';

interface PageItemProps {
    page: Page;
}

export const PageItem: React.FC<PageItemProps> = ({ page }) => {
    const navigate = useNavigate();

    const handleDescriptionClick = () => {
        navigate(`/page/${page.pageId}`);
    };

    return (
        <div className="bg-gray-800 p-4 rounded-lg shadow-md">
            <h3 className="text-white text-xl font-semibold text-center">{page.name}</h3>
            <p
                className="text-gray-300 cursor-pointer text-lg font-bold bg-gray-900 p-2 rounded-md mt-2 w-2/3 mx-auto text-center transition duration-300 hover:bg-gray-950"
                onClick={handleDescriptionClick}
            >
                {page.description}
            </p>
        </div>
    );
};
