import React from 'react';
import { PaymentLinks } from '../../models';
import { CreditCard } from 'lucide-react';

interface PaymentOptionsProps {
    links: PaymentLinks;
}

export const PaymentOptions: React.FC<PaymentOptionsProps> = ({ links }) => {
    const handlePaymentClick = (url: string) => {
        window.open(url, '_blank');
    };

    return (
        <div className="mt-8 bg-gray-800 rounded-lg p-6 shadow-lg">
            <h2 className="text-white text-2xl font-bold mb-6">Выберите способ оплаты:</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                {Object.entries(links).map(([name, url]) => (
                    <button
                        key={name}
                        className="flex items-center bg-emerald-800 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-blue-300 text-white font-medium py-3 px-5 rounded-lg transition-all duration-300"
                        onClick={() => handlePaymentClick(url)}
                    >
                        <CreditCard className="mr-3 w-8 h-8 text-white" />
                        <span>{name}</span>
                    </button>
                ))}
            </div>
        </div>
    );
};
