import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { Footer, Header, MainPage, PageComponent } from './components';
import { FundAccount } from './components/FundAccount/FundAccount';

function App() {

    return (
        <Router>
            <div className="flex flex-col min-h-screen text-center">
                <Header />

                <main className="flex-grow p-10 bg-slate-700 relative">

                    <Routes>
                        <Route path="/" element={<MainPage />} />
                        <Route path="/page/:pageId" element={<PageComponent />} />
                        <Route path="/fund-account" element={<FundAccount />} />
                    </Routes>
                </main>

                <Footer />
            </div>
        </Router>
    );
}

export default App;
