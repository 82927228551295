import axios from "axios";
import { Product } from "../models";

export const ProductService = {

    async getProductsByPageId(pageId: number) {
        try {
            const response = await axios.get<Product[]>(`/api/products/getProductsByPageId/${pageId}`);

            return response.data;
        } catch (error) {
            return [];
        }
    },

    async getProductByProductId(productId: number) {
        try {
            const response = await axios.get<Product>(`/api/products/getProductByProductId/${productId}`);

            return response.data;
        } catch (error) {
            return null;
        }
    }
}