export const Footer: React.FC = () => {
    return (
        <footer className="bg-gray-800 py-4">
            <div className="flex flex-col items-center">
                <p className="text-white text-sm">
                    Оформление заказа <a href="https://t.me/BubShopBot" className="text-blue-400 hover:underline">@BubShopBot</a>
                </p>
                <p className="text-white text-sm">
                    Техническая поддержка <a href="https://t.me/Bubs_Shop_Support" className="text-blue-400 hover:underline">@Bubs_Shop_Support</a>
                </p>
                <p className="text-white text-sm">{new Date().getFullYear()} BubsShop</p>
            </div>
        </footer>
    );
};
