import { Link } from 'react-router-dom';

export const Header: React.FC = () => {
    return (
        <header className="bg-gray-800 py-4">
            <div className="flex items-center justify-between">
                <Link to="/" className="ml-10 text-white text-xl font-bold">
                    BubsShop
                </Link>
                <Link
                    to="/fund-account"
                    className="mr-10 text-white text-lg font-bold hover:underline"
                >
                    Пополнить счёт
                </Link>
            </div>
        </header>
    );
};
