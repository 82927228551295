import React, { useState } from 'react';
import { Product } from '../../models';

interface ProductItemProps {
    product: Product;
}

export const ProductItem: React.FC<ProductItemProps> = ({ product }) => {
    const [showHint, setShowHint] = useState(false);

    const handleBuyClick = () => {
        window.open('https://t.me/BubShopBot', '_blank');
    };

    return (
        <div className="bg-gray-800 p-4 rounded-lg shadow-md flex flex-col sm:flex-row items-center sm:items-start relative">
            <div className="flex-grow text-center">
                <h3 className="text-white text-xl font-semibold">
                    {product.name}
                </h3>
                <p className="text-gray-300 text-sm mt-1">
                    Цена: {product.price}₽
                </p>
            </div>
            <div className="relative mt-4 sm:mt-0 sm:ml-4 w-full sm:w-auto text-center">
                <button
                    className="bg-gray-900 text-gray-300 text-lg font-bold px-6 py-3 rounded transition duration-300 hover:bg-gray-950 w-full"
                    onMouseEnter={() => setShowHint(true)}
                    onMouseLeave={() => setShowHint(false)}
                    onClick={handleBuyClick}
                >
                    Приобрести
                </button>
                {showHint && (
                    <div className="absolute top-full mt-2 bg-gray-900 text-white text-base rounded px-6 py-2 w-full z-10">
                        Хотите купить этот продукт? Нажмите здесь, чтобы перейти к нашему Telegram-боту
                    </div>
                )}
            </div>
        </div>
    );
};
