import React from 'react';
import { CheckCircleIcon, XCircleIcon } from 'lucide-react';

interface BannerProps {
    message: string;
    type: 'success' | 'error';
}

export const Banner: React.FC<BannerProps> = ({ message, type }) => {
    const isSuccess = type === 'success';
    const Icon = isSuccess ? CheckCircleIcon : XCircleIcon;

    return (
        <div
            className={`p-3 sm:p-4 rounded-lg shadow-md flex items-center justify-center space-x-2 sm:space-x-3 mt-4 mx-auto max-w-full sm:max-w-md
            ${isSuccess
                    ? 'bg-emerald-800 text-emerald-100'
                    : 'bg-rose-800 text-rose-100'
                }`}
        >
            <Icon className="w-4 h-4 sm:w-5 sm:h-5 flex-shrink-0" />
            <span className="text-xs sm:text-sm font-medium">{message}</span>
        </div>
    );
};
