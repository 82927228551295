import axios from "axios";
import { User } from "../models";

export const UserService = {
    async getUserByUserId(userId: number): Promise<User | undefined> {
        try {
            const response = await axios.get<User>(`/api/users/getUserByUserId/${userId}`);

            return response.data;
        } catch (error) {
            return undefined;
        }
    }
}
