import { useState, useEffect } from 'react';
import { PageService } from '../services';
import { Page } from '../models';

export const useFetchPages = () => {
    const [responseData, setResponseData] = useState<Page[]>([]);
    const [loading, setLoading] = useState(false);

    const fetchPages = async () => {
        setLoading(true);
        try {
            const data = await PageService.getPagesByParentPageId(-2);
            setResponseData(data);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPages();
    }, []);

    return { responseData, loading };
};
